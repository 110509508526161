.home {
    .features {
      background-color: #efeef0fd;
      display: flex;
      justify-content: center;
      padding: 10px 5%;
      margin: 90px 0px;
  
      &.dark {
        background-color: #0d084d;
  
        h1 {
          color: white !important;
        }
  
        i {
          font-weight: 300;
        }
  
        p {
          margin-bottom: 20px;
          color: white !important;
        }
  
        .title {
          color: rgb(255, 255, 255) !important;
          font-weight: 300 !important;
          font-size: 14px !important;
        }
        .title img{
          color: #1dbf73;
        }
  
        button {
          background-color: #1dbf73;
          border: none;
          color: white;
          padding: 10px 20px;
          border-radius: 5px;
          width: max-content;
          font-size: 16px;
          cursor: pointer;
          margin-top: 20px;
        }
      }
  
      .container {
        width: 1400px;
        display: flex;
        align-items: center;
        gap: 200px;
        padding: 30px ;
      
        .item {
          display: flex;
          flex-direction: column;
          gap: 15px;
          &:first-child {
            flex: 2;
          }
          &:last-child {
            flex: 3;
          }
  
          img{
            width: 100%;
          }
          h1 {
            font-weight: 600;
            margin-bottom: 10px;
          }
          .title {
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            font-weight: 700;
            img {
              width: 24px;
              height: 24px;
              color: #1dbf73;
            }
          }
          p {
            font-size: 18px;
            font-weight: 400;
            color: rgb(27, 27, 27);
            line-height: 28px;
            letter-spacing: 1px;
          }
  
        
        }
      }
  
    }
  }
  
  @media (max-width:900px) {
    .container{
      flex-direction: column;
    }
    .item2{
      display: none;
    }
  
  }
  