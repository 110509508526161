.navbar {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: 0.5s all ease;
  padding: 5px 10%;

  &.active {
    background-color: rgb(0, 0, 0);
    color: white;
    cursor: pointer;
    
 
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;

    .logo {
      font-size: 34px;
      font-weight: bold;

      .text {
        font-size: 30px;
      }

      .dot {
        
        font-weight: bold;
        color: #1dbf73;
        border-radius: 50%;
      }
    }
    .links {
      display: flex;
      align-items: center;
      gap: 24px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
    
  
      .post-btn{
       
        padding: 2px 10px;
        border: none;
        border-radius: 10px;
        box-shadow:  0 4px 9px orange;
        color: white;
        width: fit-content;
        cursor: pointer;
        text-decoration: none;
      }
      span {
        white-space: nowrap;
      }

      .user {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        position: relative;
        color: aqua;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
        }

        .options {
          position: absolute;
          top: 50px;
          right: 0;
          padding: 20px;
          background-color: orange;
          border-radius: 10px;
          z-index: 999;
          border: 1px solid lightgrey;
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 200px;
          font-weight: 300;
          color: white;
        }
      }
      .logoutBtn {
      
        padding: 10px 20px;
        border-radius: 25px;
        background-color:transparent;
        border:1px solid blue;
        color: blue;
        cursor: pointer;
        transition: 0.8s ease;
        text-decoration: none;

        
      }
      .logoutBtn:hover {
        background-color:blue;
        border:none;
        color: white;
      }

      .loginBtn {
        color: white;
        padding: 10px 20px;
        border-radius: 25px;
        border: none;
        background-color:blue;
        cursor: pointer;
        transition: 0.5s ease;

        
      }
      .loginBtn:hover{
        background-color:transparent;
        border:1px solid blue;
        color: blue;
      }
    }
  }

  hr {
    width: calc(100% - 2px);
    height: 0;

   
  }

  .menu {
    padding: 10px 0px;
    display: flex;
    gap: 30px;
    color: rgb(228, 228, 228);
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
  }
}
.pillar-text{
  font-size: 12px;
  color: orange;
  font-style: italic;
 
}
@media (max-width:600px) {
  .navbar .menu{
    display: none;
  }
  .register-btn, .login-btn{
    display: none;
  }
  .logo{
    padding-bottom: 20px;
  }
  .links{
    display: flex;
    align-items: center;

  }
  .links span:hover{
    background-color: blue;
  } 
  .changeDisplay{
    display: none;
  }
}
