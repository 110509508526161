.hero{
    height: 80vh;
    background-color: black;
    color: white;
    padding: 0px 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}
.hero-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hero-left h1{
    font-size: 40px;
    margin-bottom: 15px;
    text-align: center;
}
.hero-btn{
  background-color: blue;  
  color: white;
  font-size: 25px;
  padding: 10px 25px;
  border-radius: 20px;
  border: none;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.5s ease;
}
.hero-btn:hover{
    background-color: rgb(4, 4, 44);
}
.hero-btn img{
    width: 30px;
    margin-left: 10px;
}
.hero-popular{
    display: flex;
    align-items: center;
    gap: 10px;
}
.hero-right{
    background-image: url('../../Images/hero-image.png');
    width: 50%;
    height: inherit;
    background-size: cover;
    background-position: center;
}

@media (max-width:800px) {
    .hero{
        flex-direction: column;
        height: fit-content;
        padding: 0px 1%;
        padding-bottom: 110px ;
    }
    .hero-left{
        order: 2;
        margin-top: -80px;
        width: 90%;
    
        
    }
    .hero-left h1{
        font-size: 30px;
    }
    .hero-right{
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .hero-popular{
        flex-wrap: wrap;
        margin-top: 20px;
        justify-content: flex-start;
    }
}