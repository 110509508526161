/* src/App.css */






* {
  .link {
    text-decoration: none;
    color: inherit;
  }
}

* {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.container {
  width: 100%;
  height: fit-content;
}

.chat-btn {
  font-size: 16px;
  padding: 5px 30px;
  border: none;
  width: fit-content;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.5s ease all;
  cursor: pointer;
}
.chat-btn:hover {
  background-color: black;
  color: white;
}
.hire-btn {
  font-size: 16px;
  padding: 5px 70px;
  border: none;
  background-color: orange;
  width: fit-content;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.5s ease all;
  cursor: pointer;
}
.hire-btn:hover {
  background-color: black;
  color: white;
}
.getstarted-btn {
  font-size: 22px;
  border: none;
  padding: 7px 25px;
  background-color: black;
  color: white;
  border-radius: 20px;
  transition: 0.5s ease;
  cursor: pointer;
  margin-top: 20px;
}
.getstarted-btn:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
}
.error {
  color: red;
}
.explore-btn {
  margin: 10px 10%;
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  transition: 0.5s ease;
  cursor: pointer;
  margin-top: 20px;
  padding: 7px 25px;
}
.workers-headings {
  padding: 20px 9%;
}
.workers-headings h5 {
  color: rgb(19, 19, 114);
  font-style: italic;
  margin-bottom: 20px;
}
.workers-headings h1 {
  font-size: 35px;
  margin-bottom: 10px;
}
.workers-headings p {
  font-size: 18px;
  color: grey;
}
.Link{
  text-decoration: none;
}
