/* src/components/JobList.css */
.job-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 1em;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 30px;
    box-shadow: 0px 0px 5px 5px grey;
  }

  .job-list ul{
    text-decoration: none;
    list-style: none;
  }
  
  .job-list h2 {
    margin-bottom: 1em;
    font-size: 18px;
  }
  
  .job-list .job-item {
    padding: 1em;
    margin-bottom: 1em;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 0px 0px 1px 1px grey;
  }
  
  .job-list .job-item h3 {
    margin-top: 0;
  }
  .job-list .job-item h4{
    color: green;
  }
  
  .job-list .job-item p {
    margin: 0.5em 0;
  }
  .jobTitles{
    font-size: 18px;
    color: rgb(12, 12, 90);
    font-weight: bold;
  }
  .addBtn{
    background-color: green;
    color: white;
    border: none;
    font-size: 15px;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 5px;
  }
  .payBtn{
    background-color: orange;
    border: none;
    font-size: 15px;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 4px;
  }
  .dashHeader{
    display: flex;
    justify-content: space-between;
  }
  @media (max-width:500px) {
    .job-list{
      width: 300px;
    }
    
  }