/* src/components/JobForm.css */
.job-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 30px;
    box-shadow: 0px 0px 5px 5px grey;
  }
  
  .job-form h2 {
    margin-bottom: 1em;
  }
  
  .job-form input,
  .job-form textarea {
    display: block;
    width: 98%;
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #ddd;
  }
  .job-form label{
    font-size: 18px;
    color: rgb(7, 7, 44);
  }
  .job-form button {
    width: 100%;
    padding: 0.5em;
    background: #28a745;
    border: none;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .job-form button:hover {
    background: #218838;
  }
  @media (max-width:500px) {
    .job-form{
      width: 300px;
    }
  }
  