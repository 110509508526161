.contact{
    margin: 40px auto;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}
.title h2{
    padding: 10px 5%;
    font-size: 40px;
}
.contact-col{
    flex-basis: 48%;
    color: #676767;

}
.contact-col h3{
    color: #000F38;
    font-weight: 500;
    font-size: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.contact-col h3 img{
    width: 35px;
    margin-left: 10px;
}
.contact-col p{
    max-width: 450px;
    list-style: 0.3;
}
.contact-col ul li{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.contact-col ul li img{
    width: 25px;
    margin-right: 10px;
}
.contact form input, .contact form textarea{
    display: block;
    width: 100%;
    background: #EBECFE;
    padding: 15px;
    border: 0;
    outline: 0;
    margin-bottom: 15px;
    margin-top: 5px;
    resize: none;
}
.contact-col span{
    display: block;
    margin: 20px 0;
}
.dark-btn{

    padding: 14px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #212EA0;
    color: #fff;
}
.contact-col form button img{
    width: 20px;
    margin-left: 5px;
}

/*....................................RESPONSIVENESS...........................................*/
@media (max-width:700px) {
    .contact{
        display: block;
    }
    .contact-col{
        padding: 20px;
    }
}