.footer {
  display: flex;
  justify-content: center;
  color: #666;
  margin: 50px 0px;
  border-top: 1px solid grey;
  padding-top: 20px;
  background-color: #faf6f6;
}
.footer .container{
  display:flex;
  flex-direction: column;
  padding: 0px 7%;
}
.top{
  display: flex;
  justify-content: space-around;
}
.item{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bottom{
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700px) {
  .footer {
    margin: 0;
  }
  .top {
    display: none;
  }
  .item {
    padding: 5px;
  }
  hr{
    display: none;
  }
}
