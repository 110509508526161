.steps{
    padding: 5px 9%;
    margin-top: 30px;
}

.steps-row{
    width: 100%;
    display: flex;
}
.steps-col{
    width: 50%;
}
.steps-col h2{
    font-size: 26px;
    margin-top: 30px;
}
.steps-col p{
    margin-top: 10px;
    font-size: 19px;
}
.getstarted-btn{
    cursor: pointer;
}

.quote-banner{
    margin: 50px 10%;
    background-color: rgb(0, 153, 255);
    padding: 0px 50px;
    border-radius: 30px;
    display: flex;
    align-items: center;
}
.quote-col{
    width: 60%;
    color: white;
}
.quote-col h2{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 40px;
}
.quote-col h2 span{
    font-weight: 900;
}
.banner-image{
    width: 40%;
    padding-top: 0;
}
.banner-image img{
    
    margin-top: -40px;
}
hr{
    margin: 50px;
}
@media (max-width:900px) {
    .steps-row{
        flex-direction: column;
    }
    .steps-col{
        width: 100%;
        margin-bottom: 50px ;
    }
    .steps-col img{
        width: 90%;
    }
    .quote-banner{
        flex-direction: column;
        padding: 9px 10px;
        width: 90%;
        

    }
    .quote-col{
        width: 100%;
    }
    .quote-col h2{
        margin-bottom: 5px;
        font-size: 22px;
    }
    .banner-image{
        display: none;
    }
   
}
