/* src/components/Auth.css */
.auth-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 30px;
    box-shadow: 0px 0px 5px 5px grey;
    margin-top: 40px;
  }
  
  .auth-form h2 {
    margin-bottom: 1em;
  }
  
  .auth-form input {
    display: block;
    width: 97%;
    padding: 0.7em;
    margin-bottom: 1em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .auth-form label{
    font-size: 18px;
  }
  
  .auth-form button {
    width: 100%;
    padding: 0.5em;
    background: #007bff;
    border: none;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .auth-form button:hover {
    background: #0056b3;
  }
  @media (max-width:500px) {
    .auth-form{
      width: 300px;
    }
  }
  